import { useState } from "react";
import {useHistory } from "react-router-dom";
import * as Yup from "yup";
import { login } from "../auth/user-auth";
import { TbFridge } from "react-icons/tb";
import { FaShoppingBasket } from "react-icons/fa";
import { BsArrowLeftRight } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'


type Props = {};

export const Login: React.FC<Props> = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("お名前の入力は必須です"),
    password: Yup.string().required("パスワードの入力は必須です"),
  });
  const { handleSubmit, register, formState: { errors } } = useForm( 
    {
      mode: "onBlur",
      defaultValues: {
        username: "",
        password: "",
      },
    resolver: yupResolver(validationSchema)
    } );


  const handleLogin = (formVal: { username: string; password: string }) => {
    const { username, password } = formVal;
    setMessage("");
    setLoading(true);

    login(username, password).then((response) => {
      if (response.data.accessToken) {
        history.push("/branch");
        window.location.reload();
      } else {
        const resmessage =
          (response && response.data && response.data.message) || message;
        setLoading(false);
        setMessage(resmessage);
      }
    });
  };
  return (
    <>
    <h2 className="siteName">Food-Manager</h2>
      <div className="login-icon-container">
        <div className="container mt-5 d-sm-none">
          <div className="row justify-content-center">
            <h1 className="col-4 icon-container">
              <div>
                <TbFridge size={50} color={"#CCC"} />
              </div>
            </h1>
            <h1 className="col-4 icon-container">
              <div>
                <BsArrowLeftRight size={50} color={"#CCC"} />
              </div>
            </h1>
            <h1 className="col-4 icon-container">
              <div>
                <FaShoppingBasket size={50} color={"#CCC"} />
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div className="login-icon-container">
        <div className="container mt-7 d-none d-sm-block">
          <div className="row justify-content-center">
            <h1 className="col icon-container">
              <div>
                <TbFridge size={100} color={"#CCC"} />
              </div>
            </h1>
            <h1 className="col icon-container">
              <div>
                <BsArrowLeftRight size={100} color={"#CCC"} />
              </div>
            </h1>
            <h1 className="col icon-container">
              <div>
                <FaShoppingBasket size={100} color={"#CCC"} />
              </div>
            </h1>
          </div>
        </div>
      </div>
      <h2 className="loginName">ログインページ</h2>
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="form-group">
            <label htmlFor="username">お名前</label>
              <input type="text" {...register("username")} 
              className="form-control"
              id="username"
              autoComplete="given-name"
              />
            </div>
            {errors.username && (
              <div className="alert alert-danger">
                {errors.username.message}
              </div>
            )}
            <div className="form-group">
            <label htmlFor="password">パスワード</label>
              <input type="text" {...register("password")} 
              className="form-control"
              id="password"
              />
            </div>
            {errors.password && (
              <div className="alert alert-danger">
                {errors.password.message}
              </div>
            )}
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                disabled={loading}
              >
                {loading && <span className="spinner-border spiner-border-sm"></span>}
                <span>ログイン</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </form>
         </div>
     </div>
    </>
  );
};