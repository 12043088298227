import { useEffect, useState } from "react";
import Food from "../../models/Food";
import { getCurrentUser } from "../../auth/user-auth";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { getCurrentDate } from "../../util/date";

import { getShoppingFoodByUserAndCategoryIds } from "../../auth/user.shopping";
import { AddShoppingFoodComponent } from "./AddShoppingFoodComponent";
import { UpdatedShoppingFoodComponent } from "./UpdatedShoppingFoodComponent";
import { FoodListModal } from "../custom-modal/FoodListModal";
import { postFoodByUser } from "../../auth/user.food";
import { ModalComponent } from "../custom-modal/ModalComponent";
import { YesOrNoModal } from "../custom-modal/YesOrNoModal";
import { FaShoppingBasket } from "react-icons/fa";

type shoppingProps = {
  newFoodList: Food[];
  fetchFridgeAllFoodByUser: () => Promise<void>;
  newShoppingFoodList: Food[];
  fetchShoppingAllFoodByUser: () => Promise<void>;
};

export const Shopping: React.FC<shoppingProps> = ({
  newFoodList,
  fetchFridgeAllFoodByUser,
  newShoppingFoodList,
  fetchShoppingAllFoodByUser,
}) => {
  const currentUser = getCurrentUser();

  const [newVegetableList, setNewVegetableList] = useState<Food[]>([]);
  const [newMeatList, setNewMeatList] = useState<Food[]>([]);
  const [newFishList, setNewFishList] = useState<Food[]>([]);
  const [newFruitsList, setNewFruitsList] = useState<Food[]>([]);
  const [newSoyProductList, setNewSoyProductList] = useState<Food[]>([]);
  const [newDailyList, setNewDailyList] = useState<Food[]>([]);
  const [newDrinkList, setNewDrinkList] = useState<Food[]>([]);
  const [newOthersList, setNewOthersList] = useState<Food[]>([]);

  const [foodListShowModalState, setFoodListShowModalState] =
    useState<boolean>(false);
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [modalState, setModalState] = useState<boolean>(false);

  const { setValue } = useForm({
    defaultValues: {
      newFood: {
        name: "",
        quantity: 0,
        date: getCurrentDate(),
        categoryId: 1,
        categoryName: "",
      },
      newShoppingFoodList: [],
      vegetableList: [],
      meatList: [],
      fishList: [],
      fruitsList: [],
      soyProductList: [],
      dailyList: [],
      drinkList: [],
      othersList: [],
    },
  });

  const fetchShoppingFoodByUser = async () => {
    try {
      const categoryIds = [1, 2, 3, 4, 5, 6, 7, 8];
      const response = await getShoppingFoodByUserAndCategoryIds(
        currentUser.accessToken,
        categoryIds
      );

      const vegetableList = response[0].map((food: Food) => ({
        ...food,
      }));
      setNewVegetableList(vegetableList);
      setValue("vegetableList", vegetableList);

      const meatList = response[1].map((food: Food) => ({
        ...food,
      }));
      setNewMeatList(meatList);
      setValue("meatList", meatList);

      const fishList = response[2].map((food: Food) => ({
        ...food,
      }));
      setNewFishList(fishList);
      setValue("fishList", fishList);

      const fruitsList = response[3].map((food: Food) => ({
        ...food,
      }));
      setNewFruitsList(fruitsList);
      setValue("fruitsList", fruitsList);

      const soyProductList = response[4].map((food: Food) => ({
        ...food,
      }));
      setNewSoyProductList(soyProductList);
      setValue("soyProductList", soyProductList);

      const dailyList = response[5].map((food: Food) => ({
        ...food,
      }));
      setNewDailyList(dailyList);
      setValue("dailyList", dailyList);

      const drinkList = response[6].map((food: Food) => ({
        ...food,
      }));
      setNewDrinkList(drinkList);
      setValue("drinkList", drinkList);

      const othersList = response[7].map((food: Food) => ({
        ...food,
      }));
      setNewOthersList(othersList);
      setValue("othersList", othersList);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchShoppingFoodByUser();
  }, [currentUser]);

  const handleAddtoFoodList: SubmitHandler<FieldValues> = async () => {
    fetchShoppingAllFoodByUser();
    console.log("newShoppingFoodList", newShoppingFoodList);
    try {
      await postFoodByUser(newShoppingFoodList, currentUser.accessToken);
      setModalState(true);
    } catch (error) {
      console.log(error);
    }
  };

  const allModalclose = () => {
    setModalFlag(false);
    setModalState(false);
  };

  const showFridgeFoodModalOpen = () => {
    fetchFridgeAllFoodByUser();
    setFoodListShowModalState(true);
  };
  return (
    <>
      {currentUser && (
        <>
          <header className="container px-0">
            <div className="row justify-content-start">
              <div className="col-4 justify-content-center">
                <div className="fa-shopping-basket">
                  <FaShoppingBasket size={60} color={"#CCC"} />
                </div>
              </div>
              <div className="col-4 justify-content-center list-title-wrapper">
                <h4 className="list-title">お買い物LIST</h4>
              </div>
              <div className="col-4 show-button-wrapper justify-content-center">
                <button
                  className="show-inside-fridge-button btn btn-outline-secondary"
                  onClick={showFridgeFoodModalOpen}
                >
                  冷蔵庫の中身を見る
                </button>
              </div>
            </div>
          </header>

          {foodListShowModalState && (
            <FoodListModal
              foodListShowModalState={foodListShowModalState}
              newFoodList={newFoodList}
              onClose={() => setFoodListShowModalState(false)}
            />
          )}

          <div className="addShopping-Wrapper">
            <AddShoppingFoodComponent
              currentUser={currentUser}
              fetchShoppingAllFoodByUser={fetchShoppingAllFoodByUser}
            />
          </div>
          {newVegetableList.length > 0 ||
          newMeatList.length > 0 ||
          newFishList.length > 0 ||
          newFruitsList.length > 0 ||
          newSoyProductList.length > 0 ||
          newDailyList.length > 0 ||
          newDrinkList.length > 0 ||
          newOthersList.length > 0 ? (
            <>
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newVegetableList}
                setNewShoppingFoodList={setNewVegetableList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newMeatList}
                setNewShoppingFoodList={setNewMeatList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newFishList}
                setNewShoppingFoodList={setNewFishList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newFruitsList}
                setNewShoppingFoodList={setNewFruitsList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newSoyProductList}
                setNewShoppingFoodList={setNewSoyProductList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newDailyList}
                setNewShoppingFoodList={setNewDailyList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newDrinkList}
                setNewShoppingFoodList={setNewDrinkList}
                currentUser={currentUser}
              />
              <UpdatedShoppingFoodComponent
                newShoppingFoodList={newOthersList}
                setNewShoppingFoodList={setNewOthersList}
                currentUser={currentUser}
              />
            </>
          ) : (
            <p className="emptyComment">買い物リストには何も入っていません。</p>
          )}
          {newVegetableList.length > 0 ||
          newMeatList.length > 0 ||
          newFishList.length > 0 ||
          newFruitsList.length > 0 ||
          newSoyProductList.length > 0 ||
          newDailyList.length > 0 ||
          newDrinkList.length > 0 ||
          newOthersList.length > 0 ? (
          <div className="shopping-start-box container mt-3">
            <div className="row">
              <button
                className="col-12 col-md-4 mt-3 btn btn-outline-primary"
                onClick={() => setModalFlag(true)}
              >
                買い物を始める
              </button>
              <div className="col-12 col-md-8">
                <span>
               「買い物を始める」ボタンを押すと、リストの食材を冷蔵庫に一括追加できます。冷蔵庫にデータが保管されるので、削除ボタンを使ってお買い物をしても安心です。
                </span>
              </div>
            </div>
          </div>
          ) : <div></div> }
          {modalFlag && (
            <YesOrNoModal
              onClose={() => setModalFlag((priv) => !priv)}
              handleAddToFoodList={handleAddtoFoodList}
              modalFlag={modalFlag}
            />
          )}
          {modalState && (
            <ModalComponent
              modalState={modalState}
              onClose={allModalclose}
              message="追加しました"
            />
          )}
        </>
      )}
    </>
  );
};
