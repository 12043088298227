import { Link } from "react-router-dom";
import Food from "../../models/Food";

type FoodListModalProps = {
 foodListShowModalState: boolean;
  newFoodList: Food[];
  onClose: () => void;
};

export const FoodListModal: React.FC<FoodListModalProps> = ({
 foodListShowModalState,
  newFoodList = [],
  onClose,
}) => {
  const sortingFoodList = newFoodList.sort((a: Food, b: Food) => a.categoryId - b.categoryId)
  const categorizedFood = sortingFoodList.reduce((acc, food) => {
    if (!acc[food.categoryName]) {
      acc[food.categoryName] = [];
    }
    acc[food.categoryName].push(food);
    return acc;
  }, {} as { [key: string]: Food[] }); // accの型を明示的に指定

  return (
    <>
      <div className="modal-overlay-foodList"></div>
    <div className={`custom-modal ${foodListShowModalState ? "is-active" : ""}`}>
        <div className="custom-box">
          {Object.entries(categorizedFood).map(([categoryName, foods]) => (
              <ul key={categoryName}>
                <h5>{categoryName}</h5>
                {foods.map((food, index) => (
                  <li key={index}>
                    {food.name} 数量:{food.quantity} 購入日:{food.date}
                  </li>
                ))}
              </ul>
            ))}
        
          <Link to={"/fridge"}>冷蔵庫の中身を更新する方はこちら</Link>
          <div className="button-container">
          <button className="btn btn-secondary mt-2" onClick={onClose}>
            Close
          </button>
          </div>
        </div>
      {/* </div> */}
    </div>
    </>
  );
};
