import React, { useEffect, useState } from "react";
import "./App.css";
import { Nav } from "./components/Nav";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Branch } from "./components/Branch";
import { Redirect, Route, Switch } from "react-router-dom";
import { InTheFridge } from "./components/fridge/InTheFridge";
import { Shopping } from "./components/shopping/Shopping";
import Food from "./models/Food";
import { getFoodByUser } from "./auth/user.food";
import { getCurrentUser, logout } from "./auth/user-auth";
import { getShoppingFoodByUser } from "./auth/user.shopping";
import { Footer } from "./components/Footer";

const App: React.FC = () => {
  const [newFoodList, setNewFoodList] = useState<Food[]>([]);
  const [newShoppingFoodList, setNewShoppingFoodList] = useState<Food[]>([]);

  const [currentUser, setCurrentUser] = useState(getCurrentUser());

  const fetchFridgeAllFoodByUser = async () => {
    try {
      if (currentUser && currentUser.accessToken) {
        const response = await getFoodByUser(currentUser.accessToken);

        const convertedFoodList = response.data.map((food: Food) => ({
          ...food,
        }));
        setNewFoodList(convertedFoodList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchShoppingAllFoodByUser = async () => {
    try {
      if (currentUser && currentUser.accessToken) {
        const response = await getShoppingFoodByUser(currentUser.accessToken);

        const convertedFoodList = response.data.map((food: Food) => ({
          ...food,
        }));
        setNewShoppingFoodList(convertedFoodList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    logout();
    setCurrentUser(null);
  };
  useEffect(() => {
    fetchFridgeAllFoodByUser();
    fetchShoppingAllFoodByUser();
  }, [currentUser]);

  return (
    <>
      <Nav handleLogout={handleLogout} currentUser={currentUser} />
      <div className="main container mt-3">
        <Switch>
          <Route path="/" exact>      
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/branch">
            <Branch />
          </Route>
          <Route path="/fridge">
            <InTheFridge fetchFridgeAllFoodByUser={fetchFridgeAllFoodByUser} />
          </Route>
          <Route path="/shopping">
            <Shopping
              newFoodList={newFoodList}
              fetchFridgeAllFoodByUser={fetchFridgeAllFoodByUser}
              newShoppingFoodList={newShoppingFoodList}
              fetchShoppingAllFoodByUser={fetchShoppingAllFoodByUser}
            />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default App;
