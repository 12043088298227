import { Link } from "react-router-dom";
import { CurrentUser} from "../auth/user-auth";


type NavProps = {
  handleLogout: () => void
  currentUser: CurrentUser
}

export const Nav:React.FC<NavProps> = ({ handleLogout, currentUser }) => {
  return(
<nav className="navbar navbar-expand-md navbar-dark  sticky-top">
        <Link to={"/"} className="navbar-brand ms-2">
          Food-Manager  <span className="userName">
          {currentUser
                    ? currentUser.username + "さんログイン中"
                    : ""}
          </span>
        </Link>
        {currentUser ? (
          <>
            <button
              className="navbar-toggler me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-content"
              aria-controls="navbar-content"
              aria-expanded="false"
              aria-label="Toggle-navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-content">
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/fridge"} className="nav-link">
                    Fridge
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/shopping"} className="nav-link">
                    Shopping
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/"} className="nav-link" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </div>
            </div>
          </>
        ) : (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-content"
              aria-controls="navbar-content"
              aria-expanded="false"
              aria-label="Toggle-navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-content">
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/"} className="nav-link">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign up
                  </Link>
                </li>
              </div>
            </div>
          </>
        )}
      </nav>
  )
}



