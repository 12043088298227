import React from "react"

export const Footer:React.FC = () => {
  return(
    <footer className="py-2 text-light">
        
          <p>Copyright &copy;2023 SPIRE</p>
          
      </footer>
  )
}