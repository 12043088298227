import React, { useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
  useWatch,
} from "react-hook-form";
import Food from "../../models/Food";
import { CurrentUser } from "../../auth/user-auth";
import { deleteFoodByUser, updateFoodByUser } from "../../auth/user.food";
import { ModalComponent } from "../custom-modal/ModalComponent";
import { getCurrentDate } from "../../util/date";

type FoodComponentProps = {
  newFoodList: Food[];
  setNewFoodList: React.Dispatch<React.SetStateAction<Food[]>>;
  currentUser: CurrentUser;
};

export const UpdatedFoodComponent: React.FC<FoodComponentProps> = ({
  newFoodList,
  currentUser,
  setNewFoodList,
}) => {
  const { control, handleSubmit, setValue } = useForm();
  const [modalState, setModalState] = useState<boolean>(false);
  const formValues = useWatch({ control });

  const handleUpdate: SubmitHandler<FieldValues> = async () => {
    try {
      await updateFoodByUser(formValues.newFoodList, currentUser.accessToken);
      setModalState(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteFoodByUser(id, currentUser.accessToken);
      const updatedList = newFoodList.filter((food) => food.id !== id);
      setNewFoodList(updatedList);
      setValue("newFoodList", updatedList);
    } catch (error) {
      console.log(error);
    }
  };

  const customRules = {
    required: "このフィールドは必須です",
    positive: "1以上の1000以下の数値を入力してください",
    noSymbols: "記号は使用できません",
  };

  return (
    <>
      {newFoodList.length > 0 ? (
        <>
          <h5>{newFoodList[0].categoryName}</h5>

          <form onSubmit={handleSubmit(handleUpdate)}>
            {newFoodList.map((food: Food, index: number) => (
              <div key={food.id} className="container fridgeUpdateForm">
                <div className="row">
                  <Controller
                    name={`newFoodList[${index}].id`}
                    control={control}
                    defaultValue={food.id}
                    render={({ field }) => <input {...field} type="hidden" />}
                  />

                  <div className="col-4">
                    <Controller
                      name={`newFoodList[${index}].name`}
                      control={control}
                      defaultValue={food.name || ""}
                      rules={{
                        required: customRules.required,
                        pattern: {
                          value: /^[ぁ-んァ-ン一-龯a-zA-Z0-9ー－]+$/,
                          message: customRules.noSymbols,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="text"
                            className={`update-fridge form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-2">
                    <Controller
                      name={`newFoodList[${index}].quantity`}
                      control={control}
                      defaultValue={food.quantity || 1}
                      rules={{
                        required: customRules.required,
                        pattern: {
                          value: /^([1-9]\d{0,2}|1000)$/,
                          message: customRules.positive,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="number"
                            className={`update-fridge form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-4">
                    <Controller
                      name={`newFoodList[${index}].date`}
                      control={control}
                      defaultValue={food.date || getCurrentDate()}
                      rules={{ required: customRules.required }}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="date"
                            className={`update-fridge form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="col-2">
                    <button
                      type="button"
                      className="fridge-delete-button"
                      onClick={() => handleDelete(food.id)}
                    >
                      <span>削除</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              type="submit"
              className="update-button-sm m-2 btn btn-outline-secondary"
            >
              このカテゴリの内容を更新
            </button>
          </form>
          {modalState && (
            <ModalComponent
              modalState={modalState}
              onClose={() => setModalState(false)}
              message="更新しました"
            />
          )}
        </>
      ) : (
        <p></p>
      )}
    </>
  );
};
