import { Controller, useForm, useWatch } from "react-hook-form";
import { CurrentUser } from "../../auth/user-auth";
import { getCurrentDate } from "../../util/date";
import { postFoodByUser } from "../../auth/user.food";
import Food from "../../models/Food";

type AddFoodComponentProps = {
  currentUser: CurrentUser;
  fetchFridgeAllFoodByUser: () => Promise<void>;
};

export const AddFoodComponent: React.FC<AddFoodComponentProps> = ({
  currentUser,
  fetchFridgeAllFoodByUser,
}) => {
  const {
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      newFood: {
        name: "",
        quantity: 1,
        date: getCurrentDate(),
        categoryId: 1,
        categoryName: "",
      },
    },
  });

  const getCategoryId = (categoryId: number) => {
    const categoryMappings: { [key: number]: number } = {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
    };
    return categoryMappings[categoryId] || 1;
  };

const formValues = useWatch({ control });
  const onSubmit = async () => {
   
    const newFood: Food = {
      id: 0,
      name: formValues.newFood?.name || "",
      quantity: formValues.newFood?.quantity || 0,
      date: formValues.newFood?.date || getCurrentDate(),
      categoryId: getCategoryId(formValues.newFood?.categoryId || 1),
      categoryName: formValues.newFood?.categoryName || "",
    };

    try {
      await postFoodByUser([newFood], currentUser.accessToken);
      setValue("newFood", newFood);
      reset();
      fetchFridgeAllFoodByUser();
    } catch (error) {
      console.error(error);
    }
  };

  const customRules = {
    required: "このフィールドは必須です",
    positive: "1以上1000以下の数値を入力してください",
    noSymbols: "記号は使用できません",
  };

  return (
    <>
      {currentUser && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="foodAddWindow mt-3">
            <div className="container foodAddForm">
              <div className="row">
                <div className="col-8 col-md-4">
                  <label htmlFor="input1">食材名</label>
                  <Controller 
                  name="newFood.name"
                  control={control}
                  rules={{ required: customRules.required,
                    pattern: {
                      value: /^[ぁ-んァ-ン一-龯a-zA-Z0-9\p{L}\p{M}ー]+$/,
                      message: customRules.noSymbols,
                    },
                  }}
                  
                       render={( {field, fieldState} ) => (
                        <div>
                          <input {...field } id="input1" type="text" placeholder="例:大根" className={`form-control ${fieldState.error ? "is-invalid" : ""}`} />
                        {fieldState.error && (
                          <div className="invalid-feedback">
                            {fieldState.error.message}
                          </div>
                        )}
                        </div>
                       )}
                  />
                </div>
                <div className="col-4 col-md-1">
                  <label htmlFor="input2">数量</label>
                  <Controller 
                  name="newFood.quantity"
                  control={control}
                  rules={{ required: customRules.required,
                    pattern: {
                      value: /^([1-9]\d{0,2}|1000)$/,
                      message:customRules.positive,
                    }}}
                  
                       render={( {field, fieldState} ) => (
                        <div>
                          <input {...field } id="input2" type="text" className={`form-control ${fieldState.error ? "is-invalid" : ""}`} />
                        {fieldState.error && (
                          <div className="invalid-feedback">
                            {fieldState.error.message}
                          </div>
                        )}
                        </div>
                       )}
                  />
                </div>
                <div className="col-6 col-md-3">
                  <label htmlFor="input3">購入日</label>
                  <Controller 
                  name="newFood.date"
                  control={control}
                  rules={{ required: customRules.required,
                  }}
                  
                       render={( {field, fieldState} ) => (
                        <div>
                          <input {...field } id="input3" type="date" className={`form-control ${fieldState.error ? "is-invalid" : ""}`} />
                        {fieldState.error && (
                          <div className="invalid-feedback">
                            {fieldState.error.message}
                          </div>
                        )}
                        </div>
                       )}
                  />
                </div>

              
                  
                
                <div className="col-4 col-md-3">
                  <label htmlFor="select1">カテゴリ</label>
                  <Controller
                    name="newFood.categoryId"
                    control={control}
                    render={({ field }) => (
                      <select {...field} id="select1"
                      className="form-select">
                        <option value={1}>野菜</option>
                        <option value={2}>肉</option>
                        <option value={3}>魚</option>
                        <option value={4}>果物</option>
                        <option value={5}>大豆製品</option>
                        <option value={6}>乳製品</option>
                        <option value={7}>飲料</option>
                        <option value={8}>その他</option>
                      </select>
                    )}
                  />
                </div>
                <div className="buttonWrapper col-2 col-md-1">
                <div className="buttonSpacer"></div> 
                  <button type="submit" className="add-button btn btn-primary">
                    追加
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
