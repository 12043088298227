import React from "react";

type ModalProps = {
  modalState: boolean;
  onClose: () => void;
  message: string;
};

export const ModalComponent: React.FC<ModalProps> = ({
  modalState,
  onClose,
  message,
}) => {
  return (
    <>
      <div className="modal-overlay"></div>
      <div className={`custom-modal ${modalState ? "is-active" : ""}`}>
        <div className="custom-box">
          <p>{message}</p>
            <button className="btn btn-outline-secondary" onClick={onClose}>
              Close
            </button>
        </div>
      </div>
    </>
  );
};
