import { FieldValues, SubmitHandler } from "react-hook-form";

type YesOrNoModalProps = {
  onClose: () => void;
  handleAddToFoodList: SubmitHandler<FieldValues>;
  modalFlag: boolean;
};

export const YesOrNoModal: React.FC<YesOrNoModalProps> = ({
  onClose,
  handleAddToFoodList,
  modalFlag,
}) => {
  return (
    <>
      <div className="modal-overlay"></div>
      <div className={`custom-modal ${modalFlag ? "is-active" : ""}`}>
        <div className="custom-box">
          <p>現在の買い物リストを「冷蔵庫の食材一覧」に加えますか？</p>
          <div className="container">
            <div className="row justfy-content-center">
              <div className="col">
              <button
                className=" btn btn-outline-primary w-100"
                onClick={handleAddToFoodList}
              >
                はい
              </button>
              </div>
              <div className="col">
              <button className="col btn btn-outline-secondary w-100" onClick={onClose}>
                いいえ
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
