import { Controller, useForm, useWatch } from "react-hook-form";
import Food from "../../models/Food";
import { getCurrentDate } from "../../util/date";
import { CurrentUser } from "../../auth/user-auth";
import { postShoppingFoodByUser } from "../../auth/user.shopping";

type AddShoppingFoodComponentProps = {
  currentUser: CurrentUser;
  fetchShoppingAllFoodByUser: () => Promise<void>
};

export const AddShoppingFoodComponent: React.FC<
  AddShoppingFoodComponentProps
> = ({ currentUser, fetchShoppingAllFoodByUser }) => {
  
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      newShoppingFood: {
        name: "",
        quantity: 1,
        date: getCurrentDate(),
        categoryId: 1,
        categoryName: "",
      },
    },
  });

  const formValues = useWatch({
    control,
    defaultValue: {
      newShoppingFood: {
        name: "",
        quantity: 1,
        date: getCurrentDate(),
        categoryId: 1,
        categoryName: "",
      },
    },
  });

  const getCategoryId = (categoryId: number) => {
    const categoryMappings: { [key: number]: number } = {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 6,
      7: 7,
      8: 8,
    };
    return categoryMappings[categoryId] || 1;
  };

  const onSubmit = async () => {
    const newShoppingFood: Food = {
      id:0,
      name: formValues?.newShoppingFood?.name || "" ,
      quantity: formValues?.newShoppingFood?.quantity || 0,
      date: formValues?.newShoppingFood?.date || getCurrentDate(),
      categoryId: getCategoryId(formValues?.newShoppingFood?.categoryId || 1),
      categoryName: formValues?.newShoppingFood?.categoryName || "",
    };

    try {
      await postShoppingFoodByUser([newShoppingFood], currentUser.accessToken);
      setValue("newShoppingFood", newShoppingFood);
      reset();
      fetchShoppingAllFoodByUser();
    } catch (error) {
      console.error(error);
    }
  };

  const customRules = {
    required: "このフィールドは必須です",
    positive: "1以上1000以下の数値を入力してください",
    noSymbols: "記号は使用できません",
  };

  return (
    <>
      {currentUser && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="foodAddWindow mt-3">
            <h5>入力すると、下のリストに追加されます</h5>
            <div className="container addForm">
              <div className="row">
                <div className="col-8 col-md-4">
                  <label htmlFor="input1">食材名</label>
                  <Controller
                    name="newShoppingFood.name"
                    control={control}
                    rules={{required: customRules.required,
                            pattern: {
                              value: /^[ぁ-んァ-ン一-龯a-zA-Z0-9\p{L}\p{M}ー]+$/,
                              message: customRules.noSymbols
                            },
                          }}
                    render={({ field, fieldState }) => (
                      <div>
                        <input
                          {...field}
                          type="text"
                          id="input1"
                          placeholder="例:大根"
                          className={`form-control ${fieldState.error ? "is-invalid" : ""}`}
                        />
                        {fieldState.error && <div className="invalid-feedback">{fieldState.error.message}</div>}
                      </div>
                    )}
                  />
                </div>
                <div className="col-4 col-md-1">
                  <label htmlFor="input2">数量</label>
                  <Controller
                    name="newShoppingFood.quantity"
                    control={control}
                    rules={{
                      required: customRules.required,
                      pattern:{
                        value: /^([1-9]\d{0,2}|1000)$/,
                        message: customRules.positive,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <input
                          {...field}
                          type="number"
                          id="input2"
                          className={`form-control ${fieldState.error ? "is-invalid" : ""}`}
                        />
                        {fieldState.error && <div className="invalid-feedback">{fieldState.error.message}</div>}
                      </div>
                    )}
                    />
                </div>
                <div className="col-4 col-md-3">
                  <label htmlFor="select1">カテゴリ</label>
                  <Controller
                    name="newShoppingFood.categoryId"
                    control={control}
                    render={({ field }) => (
                      <select {...field} id="select1" className="form-select custom-dropdown-menu">
                        <option value={1}>野菜</option>
                        <option value={2}>肉</option>
                        <option value={3}>魚</option>
                        <option value={4}>果物</option>
                        <option value={5}>大豆製品</option>
                        <option value={6}>乳製品</option>
                        <option value={7}>飲料</option>
                        <option value={8}>その他</option>
                      </select>
                    )}
                  />
                </div>
                <div className="col-6 col-md-3">
                  <label htmlFor="input3">購入日 </label>
                  <Controller
                    name="newShoppingFood.date"
                    control={control}
                    rules={{
                      required: customRules.required,
                    }}
                    render={({ field, fieldState }) => (
                      <div>
                        <input
                          {...field}
                          type="date"
                          id="input3"
                          className={`form-control ${fieldState.error ? "is-valid": ""}`}
                        />
                        {fieldState.error && 
                        <div className="isvalid-feedback">
                          {fieldState.error.message}
                        </div>
                        }
                      </div>
                    )}
                  />
                </div>
                 <div className="buttonWrapper col-2 col-md-1">
                 <div className="buttonSpacer"></div> 
                  <button type="submit" className="add-button btn btn-primary">
                    追加
                  </button>
                </div> 

              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
