import {  useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { userRegister } from "../auth/user-auth";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


type Props = {};

export const Register: React.FC<Props> = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("お名前の入力は必須です"),
    password: Yup.string().required("パスワードの入力は必須です"),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleRegister = (formVal: { username: string; password: string }) => {
    const { username, password } = formVal;
    setMessage("");
    setLoading(true);
    userRegister(username, password).then(
      (response) => {
        setMessage(response.data.message);
        setSuccessful(true);
        setLoading(false);
      },
      (error) => {
        const resmessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setSuccessful(false);
        setMessage(resmessage);
        setLoading(false);
      }
    );
  };
  return (
    <>
      <div>
        <h2 className="registerName m-4">新規登録フォーム</h2>
      </div>
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="form-group">
              <label htmlFor="username">お名前</label>
              <input
                type="text"
                {...register("username")}
                className="form-control"
                id="username"
                autoComplete="given-name"
              />
            </div>
            {errors.username && (
              <div className="alert alert-danger">
                {errors.username.message}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="password">パスワード</label>
              <input
                type="text"
                {...register("password")}
                className="form-control"
                id="password"
              />
            </div>
            {errors.password && (
              <div className="alert alert-danger">
                {errors.password.message}
              </div>
            )}
            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-lg">
                {loading && (
                  <span className="spinner-border spiner-border-sm"></span>
                )}
                <span>Sign up</span>
              </button>
            </div>
          </form>

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="return-link">
        <Link to={"/"}>戻る</Link>
      </div>
    </>
  );
};
