import React, { useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
  useWatch,
} from "react-hook-form";
import Food from "../../models/Food";
import { getCurrentDate } from "../../util/date";
import { CurrentUser } from "../../auth/user-auth";
import { ModalComponent } from "../custom-modal/ModalComponent";
import {
  deleteShoppingFoodByUser,
  updateShoppingFoodByUser,
} from "../../auth/user.shopping";

type UpdatedShoppingFoodComponentProps = {
  newShoppingFoodList: Food[];
  setNewShoppingFoodList: React.Dispatch<React.SetStateAction<Food[]>>;
  currentUser: CurrentUser;
};

export const UpdatedShoppingFoodComponent: React.FC<
  UpdatedShoppingFoodComponentProps
> = ({ newShoppingFoodList, currentUser, setNewShoppingFoodList }) => {
  const { control, handleSubmit, setValue } = useForm();

  const formValues = useWatch({ control });

  const [modalState, setModalState] = useState<boolean>(false);

  const handleUpdate: SubmitHandler<FieldValues> = async () => {
    try {
      await updateShoppingFoodByUser(
        formValues.newShoppingFoodList,
        currentUser.accessToken
      );

      setModalState(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteShoppingFoodByUser(id, currentUser.accessToken);
      const updatedList = newShoppingFoodList.filter((food) => food.id !== id);
      setNewShoppingFoodList(updatedList);
      setValue("newShoppingFoodList", updatedList);
    } catch (error) {
      console.log(error);
    }
  };

  const customRules = {
    required: "このフィールドは必須です",
    positive: "1以上の1000以下の数値を入力してください",
    noSymbols: "記号は使用できません",
  };

  return (
    <>
      {newShoppingFoodList.length > 0 ? (
        <>
          <h5 className="categoryName">
            {newShoppingFoodList[0].categoryName}
          </h5>
          <form className="custom-form" onSubmit={handleSubmit(handleUpdate)}>
            {newShoppingFoodList.map((food: Food, index: number) => (
              <div key={food.id} className="container mt-1 shoppingUpdateForm">
                <div className="row">
                  <Controller
                    name={`newShoppingFoodList[${index}].id`}
                    control={control}
                    defaultValue={food.id}
                    render={({ field }) => <input {...field} type="hidden" />}
                  />

                  <div className="col-4">
                    <Controller
                      name={`newShoppingFoodList[${index}].name`}
                      control={control}
                      rules={{
                        required: customRules.required,
                        pattern: {
                          value: /^[ぁ-んァ-ン一-龯a-zA-Z0-9ー－]+$/,
                          message: customRules.noSymbols,
                        },
                      }}
                      defaultValue={food.name || ""}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="text"
                            className={`update-shopping form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">{fieldState.error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-2">
                    <Controller
                      name={`newShoppingFoodList[${index}].quantity`}
                      control={control}
                      rules={{
                        required: customRules.required,
                        pattern: {
                          value: /^([1-9]\d{0,2}|1000)$/,
                          message: customRules.positive,
                        },
                      }}
                      defaultValue={food.quantity || 1}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="number"
                            className={`update-shopping form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="col-4">
                    <Controller
                      name={`newShoppingFoodList[${index}].date`}
                      control={control}
                      rules={{
                        required: customRules.required,
                      }}
                      defaultValue={food.date || getCurrentDate()}
                      render={({ field, fieldState }) => (
                        <div>
                          <input
                            {...field}
                            type="date"
                            className={`update-shopping form-control ${
                              fieldState.error ? "is-invalid" : ""
                            }`}
                          />
                          {fieldState.error && (
                            <div className="invalid-feedback">
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>

                  <div className="col-2">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={() => handleDelete(food.id)}
                    >
                      <span>削除</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              type="submit"
              className="update-button-sm m-2 btn btn-outline-secondary"
            >
              このカテゴリの内容を変更
            </button>
            {modalState && (
              <ModalComponent
                modalState={modalState}
                onClose={() => setModalState(false)}
                message="変更しました"
              />
            )}
          </form>
        </>
      ) : (
        <p></p>
      )}
    </>
  );
};
