import axios from "axios";


const apiurl = "https://food-manager.jp/api/auth/";



export const userRegister = (username: string, password: string) => {
  return axios.post(apiurl + "signup", {
    username, password
  },
  {
    headers: {
      'Content-Type': 'application/json',
      withCredentials: true, 
    },
  }
  );
};

export const login = (username: string, password: string) => {
  return axios.post(apiurl + "signin", {
    username,
    password
  }, {
    headers: {
      'Content-Type': 'application/json',
      withCredentials: true, 
    }
  }).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    } else {
      return response;
    }
    return response;
  });
};

export const logout = () => {
  
  localStorage.removeItem("user");
  
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");

  if(userStr) {
    return JSON.parse(userStr);
  }
  return null;
}

export type CurrentUser = {
  [x: string]: string;
  accessToken: string;
};
