import axios from "axios";
import Food from "../models/Food";
import { getCurrentUser } from "./user-auth";

const apiurl = "https://food-manager.jp/api/shopping/";


const currentUser = getCurrentUser();

export const getShoppingFoodByUser = (accessToken: string) => {
  return axios.get(apiurl + "by-user", {
   headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    withCredentials: true, 
   },
  });
};

export const getShoppingFoodByUserAndCategoryIds = (accessToken: string, categoryIds: number[]) => {
  const promises = categoryIds.map((categoryId) => 

  axios.get(apiurl + `by-category/${currentUser.id}/${categoryId}`, {
   headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    withCredentials: true, 
   },
  })
  );
return Promise.all(promises)
  .then((responses) => responses.map((response) => response.data))
  .catch((error) => {
    console.error(error);
    throw error;
  });
};


export const postShoppingFoodByUser = (valueList: Food[], accessToken: string) => {
  return axios.post(apiurl + "food-stock", valueList,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      withCredentials: true, 
    },
  })
};

export const updateShoppingFoodByUser = async (values: Food[], accessToken: string) => {
  const foodDTOList = values.map((food) => {
    return {
      id: food.id,
      name: food.name,
      quantity: food.quantity,
      date: food.date,
      categoryId: food.categoryId,
      categoryName: food.categoryName,
    };
  });

  
    const response = await axios.put(apiurl + "food-update", foodDTOList, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        withCredentials: true, 
      },
    })
    console.log(response.data);
};

export const deleteShoppingFoodByUser = async (id: number, accessToken: string) => {
  
  const response = await axios.delete(apiurl + "food-delete/" + id,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      withCredentials: true, 
    },
  }
  );
  console.log(response);
};