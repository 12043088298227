import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TbFridge } from "react-icons/tb";
import { FaShoppingBasket } from "react-icons/fa";
import { Spinner } from "../util/Spinner";

export const Branch: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="icon-container">
            <div>
              <p>
                冷蔵庫の中を
                <br />
                見たい
              </p>
              <div className="centered-container">
                <Link to={"/fridge"} className="m-3 logo-link">
                  <TbFridge size={70} color={"#CCC"} />
                  <p className="branch-logo-text mt-3">冷蔵庫のページ</p>
                </Link>
              </div>
            </div>
            <div>
              <p>
                買い物リストを
                <br />
                作成したい
              </p>
              <div className="centered-container">
                <Link to={"/shopping"} className="m-3 logo-link">
                  <FaShoppingBasket size={70} color={"#CCC"} />
                  <p className="branch-logo-text mt-3">買い物のページ</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="return-link">
            <Link to={"/"} className="return-link m-3 link-without-underline">
              戻る
            </Link>
          </div>
        </>
      )}
    </>
  );
};
