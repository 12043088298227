import { useEffect, useState } from "react";
import { getCurrentUser } from "../../auth/user-auth";
import Food from "../../models/Food";
import { useForm } from "react-hook-form";
import { getCurrentDate } from "../../util/date";
import { getFoodByUserAndCategoryIds } from "../../auth/user.food";
import { AddFoodComponent } from "./AddFoodComponent";
import { UpdatedFoodComponent } from "./UpdatedFoodComponent";
import { TbFridge } from "react-icons/tb";

type InTheFridgeProps = {
  fetchFridgeAllFoodByUser: () => Promise<void>;
}

export const InTheFridge: React.FC<InTheFridgeProps> = ({ fetchFridgeAllFoodByUser }) => {
  const currentUser = getCurrentUser();
  const [newVegetableList, setNewVegetableList] = useState<Food[]>([]);
  const [newMeatList, setNewMeatList] = useState<Food[]>([]);
  const [newFishList, setNewFishList] = useState<Food[]>([]);
  const [newFruitsList, setNewFruitsList] = useState<Food[]>([]);
  const [newSoyProductList, setNewSoyProductList] = useState<Food[]>([]);
  const [newDailyList, setNewDailyList] = useState<Food[]>([]);
  const [newDrinkList, setNewDrinkList] = useState<Food[]>([]);
  const [newOthersList, setNewOthersList] = useState<Food[]>([]);

  const [addComponentShow, setAddComponentShow ] = useState<boolean>(false);

  const { setValue } = useForm({
    defaultValues: {
      newFood: {
        name: "",
        quantity: 0,
        date: getCurrentDate(),
        categoryId: 1,
        categoryName: "",
      },
      vegetableList: [],
      meatList: [],
      fishList: [],
      fruitsList: [],
      soyProductList: [],
      dailyList: [],
      drinkList: [],
      othersList: [],
    },
  });

  const fetchFoodByUser = async () => {
    try {
      const categoryIds = [1, 2, 3, 4, 5, 6, 7, 8];
      const response = await getFoodByUserAndCategoryIds(
        currentUser.accessToken,
        categoryIds
      );

      const vegetableList = response[0].map((food: Food) => ({
        ...food,
      }));
      setNewVegetableList(vegetableList);
      setValue("vegetableList", vegetableList);

      const meatList = response[1].map((food: Food) => ({
        ...food,
      }));
      setNewMeatList(meatList);
      setValue("meatList", meatList);

      const fishList = response[2].map((food: Food) => ({
        ...food,
      }));
      setNewFishList(fishList);
      setValue("fishList", fishList);

      const fruitsList = response[3].map((food: Food) => ({
        ...food,
      }));
      setNewFruitsList(fruitsList);
      setValue("fruitsList", fruitsList);

      const soyProductList = response[4].map((food: Food) => ({
        ...food,
      }));
      setNewSoyProductList(soyProductList);
      setValue("soyProductList", soyProductList);

      const dailyList = response[5].map((food: Food) => ({
        ...food,
      }));
      setNewDailyList(dailyList);
      setValue("dailyList", dailyList);

      const drinkList = response[6].map((food: Food) => ({
        ...food,
      }));
      setNewDrinkList(drinkList);
      setValue("drinkList", drinkList);

      const othersList = response[7].map((food: Food) => ({
        ...food,
      }));
      setNewOthersList(othersList);
      setValue("othersList", othersList);
    } catch (error) {
      console.error(error);
    }
  };

  const fridgeAddHandler = () => {
setAddComponentShow(priv => !priv);
  }

  useEffect(() => {
    fetchFoodByUser();
  }, [currentUser]);

  return (
    <>  
    <header className="container px-0">
            <div className="row justify-content-start">
              <div className="col-4 justify-content-center">
                <div className="fa-shopping-basket">
                <TbFridge size={70} color={"#CCC"}/>
                </div>
              </div>
              <div className="col-4 list-title-wrapper justify-content-center">
               <h4 className="list-title">
               冷蔵庫の<br className="br-mobile" />食材一覧
                </h4></div>
              <div className="col-4 add-button-wrapper justify-content-center">
              <button className="add-food-button p-md-3 p-sm-2 btn btn-secondary" onClick={fridgeAddHandler}>{addComponentShow ? "閉じる" : "新しく食材を追加"}</button>
     
              </div>
            </div>
          </header>
    
      {currentUser && (
        <> 
        <hr />
         {addComponentShow && (
          <div className="addFridge-Wrapper">
            <AddFoodComponent currentUser={currentUser} 
            fetchFridgeAllFoodByUser={fetchFridgeAllFoodByUser}/>
          </div>
        )}
          {newVegetableList.length > 0 ||
          newMeatList.length > 0 ||
          newFishList.length > 0 ||
          newFruitsList.length > 0 ||
          newSoyProductList.length > 0 ||
          newDailyList.length > 0 ||
          newDrinkList.length > 0 ||
          newOthersList.length > 0 ? (
        <>
          <UpdatedFoodComponent
            newFoodList={newVegetableList}
            setNewFoodList={setNewVegetableList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newMeatList}
            setNewFoodList={setNewMeatList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newFishList}
            setNewFoodList={setNewFishList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newFruitsList}
            setNewFoodList={setNewFruitsList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newSoyProductList}
            setNewFoodList={setNewSoyProductList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newDailyList}
            setNewFoodList={setNewDailyList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newDrinkList}
            setNewFoodList={setNewDrinkList}
            currentUser={currentUser}
          />
          <UpdatedFoodComponent
            newFoodList={newOthersList}
            setNewFoodList={setNewOthersList}
            currentUser={currentUser}
          />
            
        </>
          ):(
            <p className="mt-4">冷蔵庫リストには何も入っていません。追加ボタンで現在の食材を登録しましょう。</p>
          )}
        </>
      )}
    </>
  );
};
